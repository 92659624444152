<template>
  <div class="container-login">
    <div class="transbox">
      <div class="content">
        <div class="header">
          <h1 class="header-name">mobican</h1>
          <h2 class="header-comment">管理者用Webログイン画面</h2>
        </div>

        <!-- TENANT LOGIN ID -->
        <div class="input-group">
          <input
            type="text"
            v-model="tenant_login_id"
            class="form-login__input form-control"
            maxlength="100"
            placeholder="テナントログインID"
            @keypress="isPrevented($event)"
            @keyup.enter="login"
          />
        </div>

        <!-- ID -->
        <div class="input-group">
          <input
            type="text"
            v-model="login_id"
            class="form-login__input form-control"
            maxlength="100"
            placeholder="社員番号またはメールアドレス"
            @keypress="isPrevented($event)"
            @keyup.enter="login"
          />
        </div>

        <!-- PASS -->
        <div class="input-group">
          <input
            v-model="password"
            type="password"
            class="form-login__input form-control"
            maxlength="24"
            placeholder="パスワード"
            @keypress="isPrevented($event)"
            @keyup.enter="login"
          />
        </div>

        <!-- error message -->
        <div class="input-group" v-if="isWrongInfo">
          <small class="form-text text-danger error_msg">
            入力されたログイン情報に誤りがございます。
          </small>
        </div>

        <!-- save login info -->
        <div style="display: flex">
          <input type="checkbox" class="save-info-checkbox" v-model="isSaveInfo" />
          <span class="save-info-text"> ログイン情報を記憶する </span>
        </div>

        <!-- login button -->
        <button
          @click="login"
          class="btn form-login__submit form-control"
          :disabled="isEmpty(tenant_login_id) || isEmpty(login_id) || isEmpty(password)"
        >
          ログイン
        </button>

        <!-- change pass link -->
        <div class="form-login__link form-control">
          <router-link to="password-reset" class="float-right">パスワードを忘れたら</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "Login",

  mounted() {
    this.getCheckboxSaveInfoValue();
    this.getPasswordValue();
  },

  data() {
    return {
      tenant_login_id: Cookies.get("tenantInfo"),
      login_id: Cookies.get("accountInfo"),
      password: null,
      isWrongInfo: false,
      remember: false,
      isSaveInfo: false,
      //save login info time
      expiresTime: 90,
    };
  },
  methods: {
    // get password save on cookie
    getPasswordValue() {
      if (Cookies.get("passwordInfo")) {
        this.password = window.atob(Cookies.get("passwordInfo")).split("=")[1];
      } else {
        this.password = null;
      }
    },

    // get ログイン情報を記憶する info
    getCheckboxSaveInfoValue() {
      if (Cookies.get("tenantInfo") && Cookies.get("accountInfo") && Cookies.get("passwordInfo")) {
        this.isSaveInfo = true;
      }
    },

    login() {
      let that = this;
      axios.defaults.headers.common = {
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      axios
        .post(this.$store.state.serverUrl + "login/v1", {
          tenant_login_id: that.tenant_login_id,
          login_id: that.login_id,
          password: that.password,
        })
        .then(function (response) {
          //check if user login success and have permission to access (role = 0)
          if (response.status === 200 && response.data.result.role === "0") {
            let employeeName = `${response.data.result.first_name} ${response.data.result.last_name}`;

            // Save login user information.
            that.$store.dispatch("auth/saveToken", {
              token: response.data.result.access_token,
              employeeName: employeeName,
              employeeNumber: response.data.result.employee_number,
              remember: that.remember,
            });

            Cookies.set("tenantId", response.data.result.tenant_id);
            if (response.data.result.avatar_img_path)
              localStorage.setItem("avatarImg", response.data.result.avatar_img_path);
            that.$router.push({ name: "LocationManage" });

            // encode user_login_id
            const encodeString = `${that.$store.state.headers["x-api-key"]}${response.data.result.user_id}`;
            const id = window.btoa(encodeString);
            // save encoded id to cookies
            Cookies.set("userId", id);

            // encode password
            const passwordString = window.btoa(
              `${that.$store.state.headers["x-api-key"]}${that.password}`
            );

            Cookies.set("loginPassword", passwordString);

            //save login information when click checkbox
            if (that.isSaveInfo) {
              Cookies.set("tenantInfo", that.tenant_login_id, {
                expires: that.expiresTime,
              });
              Cookies.set("accountInfo", that.login_id, {
                expires: that.expiresTime,
              });
              Cookies.set("passwordInfo", passwordString, {
                expires: that.expiresTime,
              });
            } else {
              Cookies.remove("tenantInfo");
              Cookies.remove("accountInfo");
              Cookies.remove("passwordInfo");
            }
          } else {
            that.isWrongInfo = true;
          }
        })
        .catch((e) => {
          console.log(e);
          that.isWrongInfo = true;
        });
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
    isPrevented(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 /* allow to press enter and backspace */ &&
        (charCode < 48 || charCode > 57) /* allow to type character 0-9 */ &&
        (charCode < 64 || charCode > 90) /* allow to type capital character A-Z and @ */ &&
        (charCode < 97 || charCode > 122) /* allow to type normal character a-z */ &&
        charCode !== 46 /* allow to type '.' character */ &&
        charCode !== 45 /* allow to type '-' character */ &&
        charCode !== 95 /* allow to type '_' character */
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .transbox {
    width: 90%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .transbox {
    width: 80%;
  }
}

@media (min-width: 993px) and (max-width: 1599px) {
  .transbox {
    width: 60%;
  }
}

@media (min-width: 1600px) {
  .transbox {
    width: 40%;
  }
}

.container-login {
  font-family: Mina;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background: #f3fbfd;
}

.transbox {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
}

.content {
  margin: 5% 15%;
}

.header-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  text-align: center;
  margin-bottom: 30px;
  color: #8bdff9;
}

.header-comment {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 37px;
}

.form-login__input {
  height: 51.48px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 37px;
  padding-left: 22px;
  font-size: 24px;
}

.form-login__input::-webkit-input-placeholder {
  color: #c4c4c4;
}

.form-login__input:focus {
  border: 1px solid #3471b3;
}

.error_msg {
  font-size: 20px;
  margin-bottom: 37px;
  margin-top: -10px;
}

.save-info-checkbox {
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.42);
  box-sizing: border-box;
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.save-info-text {
  font-size: 20px;
  text-align: center;
  margin-left: 25px;
  color: #000000;
}

.form-login__submit {
  background: #4e92f8;
  border-radius: 10px;
  color: white;
  margin-top: 32px;
  font-size: 30px;
}

.form-login__submit:hover {
  background-color: rgba(0, 45, 245, 0.829);
}

.form-login__submit:disabled {
  background-color: #8f8c8c;
  color: #000000;
}

.form-login__link {
  margin-top: 14px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  border: none;
  background: transparent;
}
a {
  color: rgba(0, 87, 255, 0.9);
}
</style>
